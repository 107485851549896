import React, { useState } from 'react';

function RandomVideo({videoId}) {
    const [isVisible, setIsVisible] = useState(false);
    /*
    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    */

    function handleClick() {
        //setImageId(getRandomInt(1, 1000));
    }

    return (
        <div>
            {<video width="640" height="480" controls>
                    <source src={`https://llm-story-telling-app.s3.us-west-2.amazonaws.com/${videoId}.mp4`} type="video/mp4" />
                    Your browser does not support the video tag.
            </video>}
        </div>
    );
}

export default RandomVideo;
