import React, { useState } from 'react';
import PostButton2 from './PostButton2';
function PostButton({ imageId }) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    fetch('https://sl1xt1pnt0.execute-api.us-west-2.amazonaws.com/prod', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({"id": imageId,"lang": 0}),
      mode: 'cors',
      redirect: 'follow'
    })
    .then(response => {
      if (!response.ok) { throw response }
      return response.json();
    })
    .then(data => {
      setIsLoading(false);
      setResponse(data);
    })
    .catch((error) => {
      console.error('Error:', error);
      setIsLoading(false);
      setError(error.toString());
    });
  };

  return (
    <div>
      <button onClick={handleClick}> Let's generate image's Story with AI tech</button>
      {isLoading ? (
        <div>Loading ... imagination and patience are both important ...</div>
      ) : (
        <>
          {response && <div>Story: {response.body}<PostButton2 text={response.body} />
    </div>}
          {error && <div>Error: {error}</div>}
        </>
      )}
    </div>
  );
}

export default PostButton;
