import React, { useState } from 'react';
// Import the CSS
import './ToggleButtons.css';

function ToggleButtons({setIsButtonVisible}) {
  const [isButtonOneActive, setButtonOneActive] = useState(true);
  const [isButtonTwoActive, setButtonTwoActive] = useState(false);

  const handleButtonOneClick = () => {
    setButtonOneActive(true);
    setIsButtonVisible(true);
    setButtonTwoActive(false);
  };

  const handleButtonTwoClick = () => {
    setButtonOneActive(false);
    setIsButtonVisible(false);
    setButtonTwoActive(true);
  };

  return (
    <div>
      <button 
        className={`button ${isButtonOneActive ? 'active' : 'inactive'}`}
        onClick={handleButtonOneClick}
      >
        LLM英語說書人(圖生故事書 支援繁體中文)
      </button>

      <button 
        className={`button ${isButtonTwoActive ? 'active' : 'inactive'}`}
        onClick={handleButtonTwoClick}
      >
        LLM在地說書人(圖推論短影片 支援抖音上傳)
      </button>
    </div>
  );
}

export default ToggleButtons;