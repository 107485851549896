//import logo from './logo.svg';
import logo from './snoopy_color.png';
import './App.css';
import RandomImage from './RandomImage';
import RandomImage2 from './RandomImage2';
import RandomVideo from './RandomVideo';
import PostButton from './PostButton';
import React, { useState } from 'react';
import ToggleButtons from './ToggleButtons';
import DelayedEffectComponent from './DelayedEffectComponent';
function App() {
  const [imageId, setImageId] = useState(getRandomInt(1, 1000));
  const [imageId2, setImageId2] = useState(1);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [videoId, setVideoId] = useState(1);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const generateValue = () => {
    // For example, generate a random integer between 1 and 1000
    const imageId = Math.floor(Math.random() * 1000) + 1;
    setImageId(imageId);
  };

  const generateVideoValue = () => {
    // For example, generate a random integer between 1 and 1000
    const videoId = 1;
    setVideoId(videoId);
  };

  const handleToggle1 = () => {
    setIsButtonVisible(!isButtonVisible);
  };

  const handleVideoButtonClick = () => {
    setIsVideoVisible(false);
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setIsVideoVisible(true);
    }, 5000);  // 5 seconds delay
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Image to Story. Practice your english with StoryTelling !   Developed by GeniusCare
        </p>
        <ToggleButtons setIsButtonVisible={setIsButtonVisible} />
        <p>

        </p>
        {!isButtonVisible && <p>拍照與讀取使用者經緯度</p>}
        {!isButtonVisible && <RandomImage2 imageId2={imageId2} />}
        {!isButtonVisible && <button onClick={handleVideoButtonClick}> 生成在地說書影片 !</button>}
        {!isButtonVisible && isLoading && <p>影像生成中較花時間 ...</p>}
        {!isButtonVisible && isVideoVisible && <RandomVideo videoId={videoId} />}
        {isButtonVisible && <button onClick={generateValue}>Generate Random Image</button>}
        {isButtonVisible && <RandomImage imageId={imageId} />}
        {isButtonVisible && <PostButton imageId={imageId} />}
      </header>
    </div>
  );
}

export default App;
