import React, { useState } from 'react';

function PostButton2({ text }) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    fetch('https://sl1xt1pnt0.execute-api.us-west-2.amazonaws.com/prod', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({"id": -1,"lang": 1,"text":text}),
      mode: 'cors',
      redirect: 'follow'
    })
    .then(response => {
      if (!response.ok) { throw response }
      return response.json();
    })
    .then(data => {
      setIsLoading(false);
      setResponse(data);
    })
    .catch((error) => {
      console.error('Error:', error);
      setIsLoading(false);
      setError(error.toString());
    });
  };

  return (
    <div>
      <button onClick={handleClick}> 太困難了嗎? 請點擊此按鈕生成(繁中)文本</button>
      {isLoading ? (
        <div>處理中...繁體中文文本生成較花時間</div>
      ) : (
        <>
          {response && <div>Story: {response.body}</div>}
          {error && <div>Error: {error}</div>}
        </>
      )}
    </div>
  );
}

export default PostButton2;
