import React, { useState } from 'react';

function RandomImage2({imageId2}) {
    //const [imageId, setImageId] = useState(getRandomInt(1, 1000));
    /*
    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    */
    /*
    function handleButtonClick() {
        //setImageId(getRandomInt(1, 1000));
    }
    */
    return (
        <div>
            <img src={`https://llm-story-telling-app.s3.us-west-2.amazonaws.com/${imageId2}L.png`} alt="讀取錯誤請點擊上方按鈕換一張" />
        </div>
    );
}

export default RandomImage2;
